const state = {
    product_name: '',
    total: 0,
    subscription_date: '',
    has_completed_order: false,
}

const getters = {
    getProductName: state => state.product_name,
    getTotal: state => state.total,
    getSubscriptionDate: state => state.subscription_date,
    getHasCompletedOrder: state => state.has_completed_order,
}

const actions = {
    async updateProductName({ commit }, product_name) {
        commit('SET_PRODUCT_NAME', product_name);
    },
    async updateHasCompletedOrder({ commit }, has_completed_order) {
        commit('SET_HAS_COMPLETED_ORDER', has_completed_order);
    },
    async updateTotal({ commit }, total) {
        commit('SET_TOTAL', total);
    },
    async updateSubscriptionDate({ commit }, subscription_date) {
        commit('SET_SUBSCRIPTION_DATE', subscription_date);
    }
}

const mutations = {
    SET_PRODUCT_NAME(state, product_name) {
        state.product_name = product_name;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_SUBSCRIPTION_DATE(state, subscription_date) {
        state.subscription_date = subscription_date;
    },
    SET_HAS_COMPLETED_ORDER(state, has_completed_order) {
        state.has_completed_order = has_completed_order;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};