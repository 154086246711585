<template>
  <div class="bg-[#f4f6f8] flex flex-col min-h-screen items-center mt-[100px]" :class="fontClass">
    <LoadingSpinner :is-loading="EducationSectionsLoader ||
      StudyContentLoader ||
      isLoadingStudyContentTab
      " />
    <div class="flex-1 mx-auto w-full">
      <div class="flex flex-col justify-center md:items-center">
        <div>
          <BreadCrumb @section-changed="onLoad" :EducationSections="EducationSections" class="px-1 sm:px-0"
            :section="educationSectionName" />
          <PaginatedItemSelector v-if="hasTabs" :filters="tabs" class="pt-2 pb-6"
            @selected-filter="handleSelectedFilter" />
          <Content :courses="courses" v-if="hasCourses" class="w-[90%] md:w-[86.66%] mx-auto" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BreadCrumb from "@/components/StudyContent/BreadCrumb.vue";
import PaginatedItemSelector from "@/components/StudyContent/Filter.vue";
import Content from "@/components/StudyContent/Content.vue";

import { getRelatedUserEducationSections } from "@/graphql/queries/educationSections/handlingCalls";
import {
  getStudyContent,
  getStudyContentTab,
} from "@/graphql/queries/studyContent/handlingCalls";
import routes from "@/router/routes";

export default {
  name: "StudyContent",
  components: {
    PaginatedItemSelector,
    BreadCrumb,
    Content,
  },
  data() {
    return {
      EducationSections: [],
      tabs: [],
      selectedTab: {},
      courses: [],
      SelectedSection: null,
      EducationSectionsLoader: false,
      StudyContentLoader: false,
      isLoadingStudyContentTab: false,
      getRelatedUserEducationSections,
      getStudyContent,
      fetchedTabs: {},
    };
  },
  methods: {
    fetchUserEducationSections() {
      const { educationSections, loading } =
        getRelatedUserEducationSections("no-cache");

      this.$watch(
        () => educationSections.value,
        (newVal) => {
          this.EducationSections = newVal;
          this.SelectedSection = this.EducationSections.find(
            (item) => item.is_selected
          );
        },
        {
          immediate: true,
        }
      );

      this.$watch(
        () => loading.value,
        (newVal) => {
          this.EducationSectionsLoader = newVal;
        },
        {
          immediate: true,
        }
      );
    },
    fetchStudyContent() {
      const { studyContent, loading } = getStudyContent("no-cache");

      this.$watch(
        () => studyContent.value,
        (newVal) => {
          this.redirectToThankYouPageIfValid(newVal);
          this.tabs = newVal?.tabs;
          this.setDefaultTab();
        },
        {
          immediate: true,
        }
      );

      this.$watch(
        () => loading.value,
        (newVal) => {
          this.StudyContentLoader = newVal;
        },
        {
          immediate: true,
        }
      );
    },
    redirectToThankYouPageIfValid(newVal){
      const userCompletedSessionArray = newVal?.user_order_completed_session;
      const hasCompletedSession = userCompletedSessionArray?.user_has_completed_order;

      if (!hasCompletedSession){
        return;
      }


      this.$store.dispatch('UserOrderCompletedSession/updateHasCompletedOrder', true);
      this.$store.dispatch('UserOrderCompletedSession/updateProductName', userCompletedSessionArray?.product_name);
      this.$store.dispatch('UserOrderCompletedSession/updateTotal', userCompletedSessionArray?.total);
      this.$store.dispatch('UserOrderCompletedSession/updateSubscriptionDate', userCompletedSessionArray?.subscription_date);

      routes.navigateTo(routes.SUBSCRIPTION_THANK_YOU.key);
    },
    onLoad() {
      this.fetchStudyContent();
      this.fetchUserEducationSections();
    },
    async handleSelectedFilter(filter) {
      const filterId = filter?.id || null;

      if (!filterId || filterId === "0") {
        this.setDefaultTab();
        return;
      }

      const selectedTab = this.findTabById(filterId);

      if (this.$isNotEmpty(selectedTab)) {
        if (selectedTab.courses !== null) {
          this.setSelectedTab(selectedTab);
        } else {
          this.fetchAndUpdateTab(filterId);
        }
      }
    },

    setDefaultTab() {
      const defaultTab = this.tabs?.find((tab) => tab.id === "0");
      this.courses = defaultTab?.courses || [];
    },

    findTabById(tabId) {
      return this.tabs?.find((tab) => tab.id === tabId) || {};
    },

    setSelectedTab(tab) {
      this.selectedTab = tab;
      this.courses = tab?.courses;
    },

    fetchAndUpdateTab(tabId) {
      const { studyContentTab, loading } = getStudyContentTab(
        tabId,
        "no-cache"
      );

      this.$watch(
        () => studyContentTab.value,
        (newVal) => {
          this.updateSelectedTab(newVal);
        },
        {
          immediate: true,
        }
      );

      this.$watch(
        () => loading.value,
        (newVal) => {
          this.isLoadingStudyContentTab = newVal;
        },
        {
          immediate: true,
        }
      );
    },

    updateSelectedTab(tab) {
      this.selectedTab = tab;
      this.courses = tab?.courses;
      const tabIndex = this.tabs?.findIndex((t) => t.id === tab.id) || -1;
      if (tabIndex !== -1) {
        this.tabs[tabIndex].courses = tab.courses;
      }
    },
  },
  computed: {
    hasTabs() {
      return Array.isArray(this.tabs) && this.tabs.length > 0;
    },
    hasCourses() {
      return Array.isArray(this.courses) && this.courses.length > 0;
    },
    educationSectionName() {
      return this.SelectedSection?.name;
    },
    fontClass() {
      return 'font-rtl';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },
};
</script>
