import { gql } from '@apollo/client/core';

export const STUDY_CONTENT = gql`
    query GetStudyContent{
        studyContent {
            tabs {
                id
                name
                courses {
                    name
                    slug
                    progress
                    teacher {
                        name
                    }
                    photo {
                        full_link
                    }
                    lessonPhoto {
                        full_link
                    }
                }
                icon {
                    full_link
                }
            }
            user_order_completed_session {
                user_has_completed_order
                product_name
                total
                subscription_date
            }
        }

    }
`;
