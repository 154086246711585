<template>
  <div class="flex justify-center my-20 md:my-0 md:mt-10">
    <div class="flex flex-col items-center w-full max-w-lg" dir="rtl">

      <div class="m-4 text-center">
        <svgicon :data="registerDoneIcon" width="180" height="180" original />
      </div>

      <div class="congrats w-full md:w-auto md:m-4 text-center mt-10">
        <div class="font-bold">مبروووووووووووك</div>
        تم تفعيل حسابك
      </div>

      <div class="mt-5 mb-20 text-center">
        <ul class="flex flex-col md:flex-row md:gap-10">
          <li>{{productName}}</li>
          <li>{{ total }} جنيه</li>
          <li>{{subscriptionDate}}</li>
        </ul>
      </div>

      <div class="w-full md:w-auto flex flex-col items-center m-2">
        <router-link :to="{ name: 'studyContent' }" class="w-full flex items-center justify-center">
          <button class="btn w-[284px] h-[56px] rounded-[10px]">
            الذهاب إلى صفحة المنهج
          </button>
        </router-link>
      </div>

    </div>
  </div>
</template>

<script>
import registerDoneIcon from '@/assets/svg/register-done.svg';

export default {
  name: 'signUpDone',
  data() {
    return {
      registerDoneIcon: registerDoneIcon
    }
  },
  computed: {
    productName() {
      return this.$store.getters['UserOrderCompletedSession/getProductName'];
    },
    total() {
      return this.$store.getters['UserOrderCompletedSession/getTotal'];
    },
    subscriptionDate() {
      return this.$store.getters['UserOrderCompletedSession/getSubscriptionDate'];
    }
  },
}
</script>

<style scoped>
.congrats {
  color: #1C9DEB;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 30px;
}

.btn {
  background-color: #1C9DEB;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}
</style>
