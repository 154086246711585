<template>
  <div class="mb-10">
    <div>
      <div class="header font-bold-700 relative py-[6%] md:py-24 bg-white text-xl lg:text-40px md:w-11/12 m-20px">
        <span class="3xl:mr-44 2xl:mr-32 md:mr-24">
          ليه تذاكر مع الخطة ؟
        </span>
        <span class="hidden md:block absolute -top-[45%] -left-[8%] md:-left-[11%] 2xl:-left-[8%] 3xl:-left-[6%]">
          <img :src="SeniorBooks" :width="262" :height="196" />
        </span>
      </div>
    </div>

    <WhyUsContentMobile v-if="isMobile"  :book="book" :video="video" :paper="paper" :idea="idea" :heart="heart"
      :DoubleHeart="DoubleHeart" :Calendar="Calendar" :report="report" />

      
    <WhyUsContentDesktop v-else :boy="boy" :book="book" :video="video" :paper="paper" :idea="idea" :heart="heart"
      :DoubleHeart="DoubleHeart" :Calendar="Calendar" :report="report" :phone="phone" />

  </div>
</template>

<script>
import book from "@/assets/images/landing/book_new.png";
import video from "@/assets/images/landing/video.png";
import paper from "@/assets/images/landing/paper.png";
import idea from "@/assets/images/landing/idea.jpeg";
import heart from "@/assets/images/landing/heart.png";
import DoubleHeart from "@/assets/images/landing/DoubleHeart.png";
import Calendar from "@/assets/images/landing/calendar.png";
import report from "@/assets/images/landing/report.png";
import boy from "@/assets/images/landing/boy2.png";
import SeniorBooks from "@/assets/images/landing/senior-books.png";
import phone from "@/assets/images/landing/mobile-notification.png";
import WhyUsContentMobile from "@/components/Landing/WhyUsContentMobile.vue";
import WhyUsContentDesktop from "@/components/Landing/WhyUsContentDesktop.vue";
import windowSize from "@/mixins/windowSize";

export default {
  name: "LandingWhyUs",
  mixins: [windowSize],
  components: {
    WhyUsContentMobile,
    WhyUsContentDesktop
  },
  data() {
    return {
      book,
      video,
      paper,
      idea,
      heart,
      DoubleHeart,
      Calendar,
      report,
      boy,
      SeniorBooks,
      phone,
    };
  },
};
</script>

<style scoped>
.header {
  border-bottom: 5px solid #4891e0;
  border-top: 1px solid #4891e0;
  border-left: 2px solid #4891e0;
  border-right: 1px solid #4891e0;
  padding: 5px;
  border-radius: 40px;
  padding: 10px;
}

@media screen and (min-width: 767px) {
  .header {
    padding: 40px;
    border-radius: 40px 0px 0px 40px;
  }
}
</style>